@font-face {
    font-family: "moto-guzzi-icons";
    src: url("../fonts/moto-guzzi/moto-guzzi-icons.eot?#iefix") format("embedded-opentype"),
url("../fonts/moto-guzzi/moto-guzzi-icons.woff2") format("woff2"),
url("../fonts/moto-guzzi/moto-guzzi-icons.woff") format("woff"),
url("../fonts/moto-guzzi/moto-guzzi-icons.ttf") format("truetype"),
url("../fonts/moto-guzzi/moto-guzzi-icons.svg#moto-guzzi-icons") format("svg");
}

i[class^="icon-"]:before, i[class*=" icon-"]:before {
    font-family: moto-guzzi-icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-accessory-case:before {
    content: "\f101";
}
.icon-accessory-windshield:before {
    content: "\f102";
}
.icon-action-menu:before {
    content: "\f103";
}
.icon-appointment-fill:before {
    content: "\f104";
}
.icon-arrow-down:before {
    content: "\f105";
}
.icon-arrow-dx:before {
    content: "\f106";
}
.icon-arrow-small-down:before {
    content: "\f107";
}
.icon-arrow-small-dx:before {
    content: "\f108";
}
.icon-arrow-small-sx:before {
    content: "\f109";
}
.icon-arrow-small-up:before {
    content: "\f10a";
}
.icon-arrow-sx:before {
    content: "\f10b";
}
.icon-arrow-up:before {
    content: "\f10c";
}
.icon-assistance:before {
    content: "\f10d";
}
.icon-bell-fill:before {
    content: "\f10e";
}
.icon-body-shop:before {
    content: "\f10f";
}
.icon-brand:before {
    content: "\f110";
}
.icon-brochure-fill:before {
    content: "\f111";
}
.icon-brochure-vc:before {
    content: "\f112";
}
.icon-brochure:before {
    content: "\f113";
}
.icon-cabina-vc:before {
    content: "\f114";
}
.icon-calculator-fill:before {
    content: "\f115";
}
.icon-calculator-vc:before {
    content: "\f116";
}
.icon-calculator:before {
    content: "\f117";
}
.icon-calendar:before {
    content: "\f118";
}
.icon-capacity-vc:before {
    content: "\f119";
}
.icon-certification-fill:before {
    content: "\f11a";
}
.icon-certification-vc:before {
    content: "\f11b";
}
.icon-certification:before {
    content: "\f11c";
}
.icon-certified-assistance-small:before {
    content: "\f11d";
}
.icon-certified-assistance:before {
    content: "\f11e";
}
.icon-check:before {
    content: "\f11f";
}
.icon-checkup:before {
    content: "\f120";
}
.icon-chevron-up:before {
    content: "\f121";
}
.icon-close-comm:before {
    content: "\f122";
}
.icon-close-zoom:before {
    content: "\f123";
}
.icon-close:before {
    content: "\f124";
}
.icon-compact-vc:before {
    content: "\f125";
}
.icon-configuration-vc:before {
    content: "\f126";
}
.icon-contact-fill:before {
    content: "\f127";
}
.icon-contact-us:before {
    content: "\f128";
}
.icon-contract-fill:before {
    content: "\f129";
}
.icon-dealer-locator:before {
    content: "\f12a";
}
.icon-directions:before {
    content: "\f12b";
}
.icon-divider:before {
    content: "\f12c";
}
.icon-e-commerce:before {
    content: "\f12d";
}
.icon-eco-vc:before {
    content: "\f12e";
}
.icon-edit-fill:before {
    content: "\f12f";
}
.icon-edit:before {
    content: "\f130";
}
.icon-editdocument-fill:before {
    content: "\f131";
}
.icon-electric:before {
    content: "\f132";
}
.icon-email-fill:before {
    content: "\f133";
}
.icon-email:before {
    content: "\f134";
}
.icon-engine-vc:before {
    content: "\f135";
}
.icon-euro-vc:before {
    content: "\f136";
}
.icon-external:before {
    content: "\f137";
}
.icon-file-upload:before {
    content: "\f138";
}
.icon-filter-vc:before {
    content: "\f139";
}
.icon-filters:before {
    content: "\f13a";
}
.icon-finance-fill:before {
    content: "\f13b";
}
.icon-flexibility:before {
    content: "\f13c";
}
.icon-garbage:before {
    content: "\f13d";
}
.icon-gears-fill:before {
    content: "\f13e";
}
.icon-gears:before {
    content: "\f13f";
}
.icon-geolocation:before {
    content: "\f140";
}
.icon-gift-fill:before {
    content: "\f141";
}
.icon-globe-fill:before {
    content: "\f142";
}
.icon-globe-vc:before {
    content: "\f143";
}
.icon-globe:before {
    content: "\f144";
}
.icon-gps-off:before {
    content: "\f145";
}
.icon-gps-on:before {
    content: "\f146";
}
.icon-header-v2-community-login:before {
    content: "\f147";
}
.icon-heart-empty:before {
    content: "\f148";
}
.icon-heart-fill:before {
    content: "\f149";
}
.icon-helmet-fill:before {
    content: "\f14a";
}
.icon-helmet:before {
    content: "\f14b";
}
.icon-hours-fill:before {
    content: "\f14c";
}
.icon-hours:before {
    content: "\f14d";
}
.icon-ico-logged:before {
    content: "\f14e";
}
.icon-ico-login:before {
    content: "\f14f";
}
.icon-info-fill:before {
    content: "\f150";
}
.icon-info:before {
    content: "\f151";
}
.icon-insurance-fill:before {
    content: "\f152";
}
.icon-insurance:before {
    content: "\f153";
}
.icon-list:before {
    content: "\f154";
}
.icon-lounge-fill:before {
    content: "\f155";
}
.icon-lounge:before {
    content: "\f156";
}
.icon-mail-vc:before {
    content: "\f157";
}
.icon-map-list:before {
    content: "\f158";
}
.icon-map:before {
    content: "\f159";
}
.icon-megaphone-fill:before {
    content: "\f15a";
}
.icon-megaphone:before {
    content: "\f15b";
}
.icon-menu:before {
    content: "\f15c";
}
.icon-merchandising-fill:before {
    content: "\f15d";
}
.icon-merchandising:before {
    content: "\f15e";
}
.icon-mobile-fill:before {
    content: "\f15f";
}
.icon-mobile:before {
    content: "\f160";
}
.icon-motorbike:before {
    content: "\f161";
}
.icon-mute:before {
    content: "\f162";
}
.icon-paint-fill:before {
    content: "\f163";
}
.icon-paint:before {
    content: "\f164";
}
.icon-passi-vc:before {
    content: "\f165";
}
.icon-pause:before {
    content: "\f166";
}
.icon-phone-vc:before {
    content: "\f167";
}
.icon-phone:before {
    content: "\f168";
}
.icon-piaggioflat:before {
    content: "\f169";
}
.icon-pin-fill:before {
    content: "\f16a";
}
.icon-pin-vc:before {
    content: "\f16b";
}
.icon-pin:before {
    content: "\f16c";
}
.icon-play:before {
    content: "\f16d";
}
.icon-plus-fill:before {
    content: "\f16e";
}
.icon-plus:before {
    content: "\f16f";
}
.icon-preventivo:before {
    content: "\f170";
}
.icon-print-fill:before {
    content: "\f171";
}
.icon-print:before {
    content: "\f172";
}
.icon-promo-fill:before {
    content: "\f173";
}
.icon-promo:before {
    content: "\f174";
}
.icon-purchase:before {
    content: "\f175";
}
.icon-quote:before {
    content: "\f176";
}
.icon-read-more:before {
    content: "\f177";
}
.icon-recharge:before {
    content: "\f178";
}
.icon-save-fill:before {
    content: "\f179";
}
.icon-save:before {
    content: "\f17a";
}
.icon-scheduled-maintenance:before {
    content: "\f17b";
}
.icon-scooter:before {
    content: "\f17c";
}
.icon-search-fill:before {
    content: "\f17d";
}
.icon-search:before {
    content: "\f17e";
}
.icon-sentmail-fill:before {
    content: "\f17f";
}
.icon-serviziassicurativifinanziari:before {
    content: "\f180";
}
.icon-share-fill:before {
    content: "\f181";
}
.icon-social-fb:before {
    content: "\f182";
}
.icon-social-instagram:before {
    content: "\f183";
}
.icon-social-tiktok:before {
    content: "\f184";
}
.icon-social-twitter:before {
    content: "\f185";
}
.icon-social-whatsapp-fill:before {
    content: "\f186";
}
.icon-social-whatsapp:before {
    content: "\f187";
}
.icon-social-x:before {
    content: "\f188";
}
.icon-social-youtube:before {
    content: "\f189";
}
.icon-sorting:before {
    content: "\f18a";
}
.icon-spart-parts:before {
    content: "\f18b";
}
.icon-staff-fill:before {
    content: "\f18c";
}
.icon-steering-wheel:before {
    content: "\f18d";
}
.icon-store-mb:before {
    content: "\f18e";
}
.icon-store:before {
    content: "\f18f";
}
.icon-swatch-active:before {
    content: "\f190";
}
.icon-swatch:before {
    content: "\f191";
}
.icon-tachimeter-fill:before {
    content: "\f192";
}
.icon-tachimeter:before {
    content: "\f193";
}
.icon-technology-2:before {
    content: "\f194";
}
.icon-technology:before {
    content: "\f195";
}
.icon-tick-fill:before {
    content: "\f196";
}
.icon-tick:before {
    content: "\f197";
}
.icon-tire:before {
    content: "\f198";
}
.icon-tow:before {
    content: "\f199";
}
.icon-unmute-vc:before {
    content: "\f19a";
}
.icon-unmute:before {
    content: "\f19b";
}
.icon-user-fill:before {
    content: "\f19c";
}
.icon-user-vc:before {
    content: "\f19d";
}
.icon-user:before {
    content: "\f19e";
}
.icon-warning-fill:before {
    content: "\f19f";
}
.icon-warning:before {
    content: "\f1a0";
}
.icon-wash-fill:before {
    content: "\f1a1";
}
.icon-wash:before {
    content: "\f1a2";
}
.icon-wifi:before {
    content: "\f1a3";
}
