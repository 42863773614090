/**
 * Design System for Piaggio Group
*/
@import "abstracts";

// Brand specific vars
@import "tokens/tokens-motoguzzi";

// Vendor specific variables
@import "vendors/vendors";

@import "html"; //temporary style depending on html data

.theme-moto-guzzi {
  @import "typography/var_icons-moto-guzzi";
  @import "typography/santander_custom_motoguzzi";
  @import "main";
}
